<div *ngFor="let linkSection of linkSectionsCopy" class="docs-toc-container">
  <nav [attr.aria-label]="linkSection.name + ' Table of Contents'" class="nav d-flex flex-column">
    <ul>
      <li *ngFor="let link of linkSection.links; let i = index" class="nav-item">
        <a
          tabindex="0"
          role="button"
          class="docs-level-{{ link.type }} docs-link"
          id="nav-link-{{ i }}"
          [ngClass]="{
            'blue-border': blueBorder && selectedIndex === i,
            'grey-border': !blueBorder || selectedIndex !== i,
          }"
          [class.docs-active]="link.active"
          (mouseover)="changeBorder(i, true)"
          (mouseout)="changeBorder(i, false)"
          (click)="navigateTo(link)"
        >
          {{ link.name }}
        </a>
      </li>
    </ul>
  </nav>
</div>
