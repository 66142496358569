import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AdvancedTextSearchService } from '@ctel/gaw-commons';
import { ArchivioFiscaleService, DocumentsService2, UiConfigurationService } from '@ctel/gawcons-commons';
import {
  AbstractFilterEffects,
  DocumentActions,
  FilterAction,
  FilterActions,
  FilterState,
  getRouterInfo,
  IAdvancedTextSearchUrl,
  IFilterStatus,
  IFilterUrl,
  IFullTextSearchUrl,
  IOrderByUrl,
  IOuterFilterUrl,
  IPagingUrl,
  IRouterStateUrl,
} from '@ctel/search-filter-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ErrorTypes } from 'app/core/common/error';
import { EMPTY } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { NotificationType } from '../../../../../../../../src/app/core/common/notification';
import { NotificationService } from '../../../../../../../../src/app/core/common/notification/notification.service';
import { FiscalYearModalComponent } from '../../../modals/fiscal-year-modal/fiscal-year-modal.component';
import { DocumentsHttpService } from '../../documents-http.service';
import {
  getRelatedSectionData,
  relatedSearchDataFetchedGAWCONS,
  sectionColumnsFetchedGAWCONS,
} from '../../documents/store/document.extended';
import { FilterService2 } from '../filter.service';
import * as ExtendedFilterActions from './filter.actions';
import ChangeFilterRequestedKind = FilterActions.ChangeFilterRequestedKind;

const PATH = 'gawcons/gcons_archivioFiscale';

@Injectable({
  providedIn: 'root',
})
export class FilterEffects extends AbstractFilterEffects {
  /**
   * Effetto che gestisce il side effect di aver fetchato i filtri preferiti,
   * e per i quali è necessario recuperare nuovi documenti.
   */
  onFavoriteFiltersFetched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.favoriteFiltersFetched),
      withLatestFrom(this.store.pipe(select(getRouterInfo))),
      filter(([, routerInfo]) => routerInfo.state.url.indexOf(PATH) !== -1),
      switchMap(() => [DocumentActions.fetchDocuments(true), DocumentActions.fetchDocumentsActions()]),
    ),
  );

  onRelatedSearchDataRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExtendedFilterActions.relatedSearchDataRequested),
      withLatestFrom(this.store.pipe(select(getRouterInfo))),
      switchMap(([action, routerInfo]) => {
        const idPdv = routerInfo.state.params['idPdv'];
        return this.uiConfigurationService
          .getRelatedData(
            routerInfo.state.params['license'],
            routerInfo.state.params['siacode'],
            idPdv ? 'gcons_archivioFiscalePdv' : 'gcons_archivioFiscale',
          )
          .pipe(
            switchMap((relatedSectionData) => {
              const newFilterStatus: IFilterStatus = this.documentsService.buildFilterPayload(
                routerInfo.state.params['license'],
                routerInfo.state.params['siacode'],
                routerInfo.state.params['doctype'],
                action.payload.filterResponse,
              );
              return [
                relatedSearchDataFetchedGAWCONS(relatedSectionData),
                sectionColumnsFetchedGAWCONS(action.payload.primaryConfig, action.payload.secondaryConfig),
                FilterActions.favoriteFiltersFetched({ filterStatus: newFilterStatus }),
              ];
            }),
            catchError((error: unknown) => this.handleError(error)),
          );
      }),
    ),
  );

  onMostraTutti$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FilterActions.editFilterRequested),
        withLatestFrom(this.store.pipe(select(getRouterInfo))),
        filter(([, routerInfo]) => routerInfo.state.url.indexOf(PATH) !== -1),
        tap(([action]) => {
          this.notificationService.showModal(NotificationType.GENERAL, {
            title: action.filter.metadataDescription,
            customFooter: true,
            childComponent: FiscalYearModalComponent,
            childData: action,
            disableClickOutside: false,
          });
        }),
      ),
    { dispatch: false },
  );

  onResetFilter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FilterActions.changeFilterRequested),
        withLatestFrom(this.store.pipe(select(getRouterInfo))),
        filter(
          ([action, routerInfo]) =>
            routerInfo.state.url.indexOf(PATH) !== -1 &&
            (action.kind === ChangeFilterRequestedKind.ResetFilter || action.kind === ChangeFilterRequestedKind.Reset),
        ),
        tap(([action, routerInfo]) => {
          this.store.dispatch(DocumentActions.fetchDocuments({ payload: { fromFavourites: false } }));
        }),
      ),
    { dispatch: false },
  );

  onSectionColumnsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExtendedFilterActions.sectionColumnsRequested),
      withLatestFrom(this.store.pipe(select(getRouterInfo))),
      filter(
        ([, routerInfo]) =>
          routerInfo.state.params['registry'] !== undefined &&
          routerInfo.state.params['license'] !== undefined &&
          routerInfo.state.params['siacode'] !== undefined,
      ),
      switchMap(([action, routerInfo]) =>
        this.archivioFiscaleService
          .getColumns(
            routerInfo.state.params['registry'],
            routerInfo.state.params['license'],
            routerInfo.state.params['siacode'],
          )
          .pipe(
            switchMap((sectionColumns) => [
              ExtendedFilterActions.relatedSearchDataRequested(
                action.payload.filterResponse,
                sectionColumns.primaryConfig,
                sectionColumns.secondaryConfig,
              ),
            ]),
            catchError((error: unknown) => {
              if (error?.['type'] === ErrorTypes.HTTP_NOT_FOUND)
                return [ExtendedFilterActions.relatedSearchDataRequested(action.payload.filterResponse, [], [])];

              return EMPTY;
            }),
          ),
      ),
    ),
  );

  onAdvancedTextSearchMetadataRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.advancedTextSearchMetadataRequested),
      withLatestFrom(this.store.pipe(select(getRouterInfo)), this.store.pipe(select(getRelatedSectionData))),
      filter(([, routerInfo]) => routerInfo.state.url.indexOf('gawcons') !== -1),
      switchMap(([, routerInfo, relatedSectionData]) =>
        this.archivioFiscaleService
          .whenMetadataDescriptions(
            routerInfo.state.params['registry'],
            routerInfo.state.params['license'],
            routerInfo.state.params['siacode'],
            relatedSectionData,
          )
          .pipe(
            map((docSeriesMetadataDesc) => {
              const sorted = this.advancedTextSearchService.sortTextSearchMetadata(
                docSeriesMetadataDesc,
                relatedSectionData,
              );
              return FilterActions.advancedTextSearchMetadataFetched({ advancedTextSearch: sorted });
            }),
            catchError((error: unknown) => this.handleError(error)),
          ),
      ),
    ),
  );

  /**
   * Effetto che gestisce il side effect della richiesta dei filtri preferiti,
   * che è necessario recuperare.
   */
  onFavouritesFiltersRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.favouritesFiltersRequested),
      withLatestFrom(this.store.pipe(select(getRouterInfo))),
      filter(([, routerInfo]) => routerInfo.state.url.indexOf(PATH) !== -1),
      switchMap(([, routerInfo]) => {
        const idPdv = routerInfo.state.params['idPdv'];
        return this.documentsHttpService
          .whenFavoriteFilters(
            routerInfo.state.params['registry'],
            routerInfo.state.params['license'],
            routerInfo.state.params['siacode'],
            idPdv ? 'gcons_archivioFiscalePdv' : 'gcons_archivioFiscale',
          )
          .pipe(
            take(1),
            map((filtersResponse) => {
              this.documentsService.setErrorLoadingDocs(false);
              // const newFilterStatus: IFilterStatus = {
              // 	docSeriesId: routerInfo.state.params['doctype'],
              // 	siaCode: routerInfo.state.params['siacode'],
              // 	licenseId: routerInfo.state.params['license'],
              // 	filters: filtersResponse.filters,
              // 	search: filtersResponse.search,
              // 	paging: filtersResponse.paging,
              // 	orderBy: filtersResponse.orderBy
              // };
              return ExtendedFilterActions.sectionColumnsRequested(filtersResponse);
              // return FilterActions.favoriteFiltersFetched({ filterStatus: newFilterStatus });
            }),
            catchError((error: unknown) => {
              this.documentsService.setErrorLoadingDocs(true);
              return this.handleError(error);
            }),
          );
      }),
    ),
  );

  constructor(
    protected actions$: Actions,
    protected store: Store<FilterState>,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    private documentsHttpService: DocumentsHttpService,
    private documentsService: DocumentsService2,
    protected filterService: FilterService2,
    private uiConfigurationService: UiConfigurationService,
    private advancedTextSearchService: AdvancedTextSearchService,
    private archivioFiscaleService: ArchivioFiscaleService,
    private notificationService: NotificationService,
    protected ngZone: NgZone,
  ) {
    super(actions$, store, filterService, router, activatedRoute, ngZone);
  }

  protected doOnChangeFilterRequested(
    action: FilterAction,
    routerInfo: { state: IRouterStateUrl; navigationId: number },
    filtersUrl: IFilterUrl[],
    outerFiltersUrl: IOuterFilterUrl[],
    pagingFiltersUrl: IPagingUrl,
    orderByFiltersUrl: IOrderByUrl[],
    fullTextSearchUrl: IFullTextSearchUrl,
    advancedTextSearchUrl: IAdvancedTextSearchUrl[],
  ): void {
    if (routerInfo.state.url.indexOf(PATH) === -1) return;

    if (action.kind === ChangeFilterRequestedKind.PagingChanged) this.documentsService.setLoadingDocsOnPaging(true);
    else {
      this.documentsService.setLoadingDocsAfterFilterApplication(true);
      this.documentsService.setLoadingDocsOnPaging(false);
    }
    super.doOnChangeFilterRequested(
      action,
      routerInfo,
      filtersUrl,
      outerFiltersUrl,
      pagingFiltersUrl,
      orderByFiltersUrl,
      fullTextSearchUrl,
      advancedTextSearchUrl,
    );
  }

  protected doNavigationChangedOnDocTypeParamsChanged() {
    this.documentsService.setLoadingDocs(true);
    return super.doNavigationChangedOnDocTypeParamsChanged();
  }

  protected getDocumentDetailUrl(): string {
    return '/details/';
  }

  protected getDocumentsListUrl(): string {
    return '/gawcons/gcons_archivioFiscale';
  }

  protected getUrlCommands(routerInfo: { state: IRouterStateUrl; navigationId: number }): string[] {
    const urlCommands: string[] = [];
    urlCommands.push(this.getDocumentsListUrl());
    urlCommands.push(routerInfo.state.params['license']);
    urlCommands.push(routerInfo.state.params['siacode']);
    urlCommands.push(routerInfo.state.params['section']);
    return urlCommands;
  }

  protected getParamsDistinctCriteria(prev: Params, succ: Params): boolean {
    if (
      this.router.url.indexOf(this.getDocumentsListUrl()) === -1 &&
      this.router.url.indexOf('/details/gawcons') === -1
    )
      return false;

    return super.getParamsDistinctCriteria(prev, succ);
  }
}
