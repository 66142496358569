import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { pulseAnimation } from 'app/core/common/animations/pulse-animation';
import { Options } from 'app/core/common/placeholder/error-placeholder/directives/options';
import { PlaceholderService } from 'app/core/common/placeholder/placeholder.service';
import { BrowserCheck } from 'app/core/common/utilities/browser/browser-check';
import { DryFileReader } from 'app/core/common/utilities/file/dry-file-reader';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { DocumentAttachmentPdf } from 'app/shared/components/display-document/document-attachment-pdf';
import { AbstractViewerComponent } from 'app/shared/components/display-document/viewers/abstract-viewer/abstract-viewer.component';
import { LoadingStatus } from 'app/shared/components/display-document/viewers/pdf-viewer/loading-status.enum';
import { PdfBinaryMode } from 'app/shared/components/display-document/viewers/pdf-viewer/pdf-binary-mode.enum';
import { PdfDisplayMode } from 'app/shared/components/display-document/viewers/pdf-viewer/pdf-display-mode.enum';
import { PDFDocumentProxy, PDFSource } from 'ng2-pdf-viewer';
import { NGXLogger } from 'ngx-logger';
import { ReplaySubject, of } from 'rxjs';
import { delay, switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'dry-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  animations: [
    trigger('loadingError$', [
      transition(
        '* => *',
        useAnimation(pulseAnimation, {
          params: {
            timings: '200ms cubic-bezier(.11,.99,.83,.43)',
            scale: 1.1,
          },
        }),
      ),
    ]),
  ],
})
export class PdfViewerComponent extends AbstractViewerComponent<DocumentAttachmentPdf> implements OnInit, OnDestroy {
  // Attributi configurabili
  @Input() public displayMode = PdfDisplayMode.IFRAME;
  @Input() public width = '100%';
  @Input() public height = '100%';
  @Input() public initialZoom = 1.0;
  @Input() public minZoom = 0.4;
  @Input() public maxZoom = 4.0;
  @Input() public zoomGap = 0.2;
  @Input() public binaryMode = PdfBinaryMode.ARRAY_BUFFER;
  @Input() public showAll = false;
  @Input() public stickToPage = true;
  @Input() public initialPage = 1;
  @Input() public smallPageGap = 1;
  @Input() public bigPageGap = 15;
  public faIcons = FaIcons;

  // Enums
  public pdfDisplayMode = PdfDisplayMode;
  public pdfProxy: PDFDocumentProxy;

  // Attributi relativi al contenuto in byte
  public documentContent$ = new ReplaySubject<string | Uint8Array | PDFSource>(1);

  public zoom = this.initialZoom;
  public page = this.initialPage;
  public firstPage = 1;
  public lastPage = 1;

  // Attributi Internet Explorer
  public isInternetExplorer = BrowserCheck.isInternetExplorer();

  // Attributi comuni
  public mimeType = 'application/pdf';
  public safeContentUrl: SafeUrl;

  public errorOptions: Options = {
    message: 'Documento non disponibile.',
    icon: `${this.faIcons.FAR_PDF}`,
    onRetry: () => this.refreshContent(),
    applyDefaultSize: true,
  };

  constructor(
    private domSanitizer: DomSanitizer,
    protected placeHolderService: PlaceholderService,
    private logger: NGXLogger,
  ) {
    super(placeHolderService);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.isInternetExplorer) this.logger.warn('Rilevato Internet Explorer, fallback modalità compatibilità.');

    // Quando arriva il contenuto del documento, lo mostro
    this.initDocumentContent();
  }

  afterLoad(pdf: PDFDocumentProxy) {
    this.pdfProxy = pdf;
    this.lastPage = pdf.numPages;
    // Sblocca la UI
    this.setLoadingStatus(LoadingStatus.loaded);
  }

  public decreaseZoom(element: HTMLElement) {
    if (this.zoom > this.minZoom) this.zoom -= this.zoomGap;

    element.blur();
    return this.zoom;
  }

  public increaseZoom(element: HTMLElement): number {
    if (this.zoom < this.maxZoom) this.zoom += this.zoomGap;

    element.blur();
    return this.zoom;
  }

  resetZoom() {
    this.zoom = this.initialZoom;
  }

  public decreasePage(gap: number, element: HTMLElement): number {
    if (this.canDecreasePage(gap)) this.page -= gap;
    else this.page = this.firstPage;

    element.blur();
    return this.page;
  }

  public increasePage(gap: number, element: HTMLElement): number {
    if (this.canIncreasePage(gap)) this.page += gap;
    else this.page = this.lastPage;

    element.blur();
    return this.page;
  }

  public canDecreasePage(gap: number) {
    return this.page - gap >= this.firstPage;
  }

  public canIncreasePage(gap: number) {
    return this.page + gap <= this.lastPage;
  }

  public goToFirstPage(element: HTMLElement): number {
    this.page = this.firstPage;
    element.blur();
    return this.page;
  }

  public goToLastPage(element: HTMLElement): number {
    this.page = this.lastPage;
    element.blur();
    return this.page;
  }

  refreshContent() {
    this.initDocumentContent();
  }

  setLoadingStatus(value: LoadingStatus) {
    this.loadingStatuse$.next(value);
  }
  protected initDocumentContent() {
    // Avvia il blocco della UI
    this.setLoadingStatus(LoadingStatus.loading);

    this.documentAttachment$
      .pipe(
        // Ritardo aggiunto per dare un feedback all'utente
        delay(300),
        take(1),
        switchMap((documentContent: DocumentAttachmentPdf) => documentContent.content),
        switchMap((result) => {
          if (result.type !== this.mimeType) {
            this.errorOptions = {
              message:
                'Formato non visualizzabile. È possibile scaricare direttamente il file tramite le azioni a fianco',
              icon: `${this.faIcons.FAR_PDF}`,
              onRetry: () => this.refreshContent(),
              applyDefaultSize: true,
            };
            this.setLoadingStatus(LoadingStatus.error);
            return;
          }
          if (this.isInternetExplorer || PdfDisplayMode.PDF_VIEWER) {
            const fileReader = new DryFileReader();
            const blob = new Blob([result], { type: this.mimeType });

            // Cambia il modo in cui il blob viene rappresentato all'interno del visualizzatore
            if (this.binaryMode === PdfBinaryMode.UINT8_ARRAY)
              return fileReader.asUint8Array(blob).pipe(tap((content) => this.sendDocumentContent(content)));
            else
              return fileReader
                .asArrayBuffer(blob)
                .pipe(tap((content) => this.sendDocumentContent(content as PDFSource)));

            // Il set status loaded Lo sblocco della UI viene fatto nell'afterLoad, dopo che il componente pdf-viewer ha
            // caricato correttamente il contenuto del file
          } else {
            const contentUrl = URL.createObjectURL(result);
            this.safeContentUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(contentUrl);
            // Sblocca la UI
            this.setLoadingStatus(LoadingStatus.loaded);
            return of(null);
          }
        }),
        take(1),
      )
      .subscribe({
        error: () => this.setLoadingStatus(LoadingStatus.error),
      });
  }

  private sendDocumentContent(value: string | Uint8Array | PDFSource) {
    this.documentContent$.next(value);
  }
}
