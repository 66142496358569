import { Injectable, OnDestroy } from '@angular/core';
import { NavigationExtras, Params, Router } from '@angular/router';
import { AuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest } from 'rxjs';
import { delay, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AuthConfigService, IAuthConfig } from '../auth-config';

interface IAuthService {
  isOidcInitialized$: Observable<boolean>;

  isAuthorized(): Observable<boolean>;

  checkAuth(): Observable<boolean>;

  whenToken(): Observable<string>;

  login(): void;

  access(): void;

  logout(): void;

  navigateToUnauthorized(): void;

  navigateToForbidden(): void;

  navigateToAccessDenied(): void;
}

@Injectable({ providedIn: 'root' })
export class AuthService implements IAuthService, OnDestroy {
  // Route di errore
  private unauthorizedRoute: string;
  private forbiddenRoute: string;
  private accessDenied: string;

  // Routes predefinite
  private defaultAfterLoginRoute: string;
  private defaultRoute: string;

  // Eventi
  private oidcInitialized$ = new BehaviorSubject(false);

  private redirectStoreKey = '_auth_redirect_url';
  private redirectParamsKey = '_auth_redirect_params';
  private readonly hasStorage: boolean;
  private token$ = new ReplaySubject<string>(1);
  private checkAuth$ = new ReplaySubject<boolean>(1);

  private destroy$ = new ReplaySubject<void>(1);

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private readonly authConfigService: AuthConfigService,
    private router: Router,
  ) {
    // Inizializzazione storage Oidc
    this.hasStorage = typeof Storage !== 'undefined';

    this.oidcInitialized$
      .pipe(
        filter((isOidcInitialized) => isOidcInitialized),
        switchMap(() => this.oidcSecurityService.checkAuth()),
        takeUntil(this.destroy$),
      )
      .subscribe((result) => this.checkAuth$.next(result.isAuthenticated));

    this.authConfigService
      .getAuthConfig()
      .pipe(
        filter((config) => !!config),
        takeUntil(this.destroy$),
      )
      .subscribe((config) => {
        this.initConfigRoute(config);
        this.oidcInitialized$.next(true);
      });

    this.isAuthorized()
      .pipe(
        filter((isAuth) => isAuth),
        switchMap(() => this.oidcSecurityService.getAccessToken()),
        takeUntil(this.destroy$),
      )
      .subscribe((token) => {
        this.token$.next(token);
      });
  }

  /** Indica se il servizion di autenticazione è inizializzato. */
  get isOidcInitialized$() {
    return this.oidcInitialized$.asObservable();
  }

  public get defaultRoutePath() {
    return this.defaultRoute;
  }

  /**
   * Indica se l'utente è autorizzato.
   * @return Observable<boolean>
   */
  public isAuthorized(): Observable<boolean> {
    return combineLatest({
      isOidcInitialized: this.oidcInitialized$.asObservable(),
      isAuthenticated: this.oidcSecurityService.isAuthenticated$.pipe(
        delay(1000),
        map((authenticatedResult) => authenticatedResult.isAuthenticated),
      ),
    }).pipe(map(({ isOidcInitialized, isAuthenticated }) => isOidcInitialized && isAuthenticated));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Ritorna il token JWT.
   * @return Observable<string>
   */
  public whenToken(): Observable<string> {
    return this.token$.asObservable();
  }

  public sendToken(string: string) {
    this.token$.next(string);
  }

  public checkAuth(): Observable<boolean> {
    return this.checkAuth$.asObservable();
  }

  /**
   * Esegue una richiesta di login verso il server.
   * @param bridgetk parametro Credem per la differenziazione delle login
   */
  public login(bridgetk?: string | number | boolean): void {
    localStorage.removeItem('alreadyShownNews');
    let bridgetkParam: AuthOptions;

    if (bridgetk)
      bridgetkParam = {
        customParams: { bridgetk },
      };

    this.oidcSecurityService.authorize(null, bridgetkParam);
  }

  /** Esegue l'accesso al portale. */
  public access() {
    const path = this.getRedirectUrl() || this.defaultAfterLoginRoute;
    const queryParams = this.getRedirectParams();
    const url = path.replace(/\?.*/, '');
    this.router.navigate([url], { queryParams }).finally();
  }

  /** Esegue il logout dal portale. */
  public logout(): void {
    // Because we are using a Reference token as Access token we can revoke it.
    // this.revokeToken();
    this.removeRedirectUrl();
    this.removeRedirectParams();
    localStorage.removeItem('alreadyShownNews');
    // Faccio il redirect verso STS
    this.oidcSecurityService
      .getEndSessionUrl()
      .pipe(
        take(1),
        tap((url) => (window.location.href = url)),
        switchMap(() => this.oidcSecurityService.logoff()),
      )
      .subscribe({
        next: () => null,
      });
  }

  /** Esegue la navigazione verso la pagina di unauthorized. */
  public navigateToUnauthorized(extra?: NavigationExtras): void {
    this.navigateToPage(this.unauthorizedRoute, extra);
  }

  /** Esegue la navigazione verso la pagina di forbidden. */
  public navigateToForbidden(extra?: NavigationExtras): void {
    this.navigateToPage(this.forbiddenRoute, extra);
  }

  /** Esegue la navigazione verso la pagina di access-denied. */
  public navigateToAccessDenied(extra?: NavigationExtras): void {
    this.navigateToPage(this.accessDenied, extra);
  }

  public setRedirectUrl(value: string): void {
    if (this.hasStorage) localStorage.setItem(this.redirectStoreKey, value);
  }

  public removeRedirectParams(): void {
    if (this.hasStorage) localStorage.removeItem(this.redirectParamsKey);
  }

  public setRedirectParams(params: { [p: string]: unknown }): void {
    if (this.hasStorage) {
      const newParams = {};

      // Non salvo nello store il token bridgetk
      for (const prop in params) if (prop !== 'bridgetk') newParams[prop] = params[prop];

      localStorage.setItem(this.redirectParamsKey, JSON.stringify(newParams));
    }
  }

  public saveActualPath(params?: Params) {
    const targetPath = window.location.pathname;
    const isLanding = targetPath === this.defaultRoute;
    const isUnauthorized = targetPath === this.unauthorizedRoute;
    // Se il pathname è uno slash, salvo nello storage stringa vuota, altrimento Microsoft Edge non fa il redirect correttamente
    const isSlash = targetPath === '/';

    // Salvo il redirect se non sono nella landing o nell'unauthorized
    this.setRedirectUrl(isLanding || isUnauthorized || isSlash ? '' : targetPath);
    const queryParams = params || Object.keys(params).length > 0 ? params : this.paramsToObject(window.location.search);
    this.setRedirectParams(queryParams);
  }

  private removeRedirectUrl(): void {
    if (this.hasStorage) localStorage.removeItem(this.redirectStoreKey);
  }

  private getRedirectUrl(): string {
    if (this.hasStorage) return localStorage.getItem(this.redirectStoreKey);

    return null;
  }

  private getRedirectParams(): { [p: string]: unknown } {
    if (this.hasStorage) {
      const params = localStorage.getItem(this.redirectParamsKey);
      return JSON.parse(params);
    }
    return null;
  }

  private paramsToObject(s: string) {
    const pairs = s.substring(1).split('&');
    const obj = {};
    for (const i in pairs) {
      if (pairs[i]) continue;

      const pair = pairs[i].split('=');
      obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
    return obj;
  }

  private navigateToPage(path: string, extras?: NavigationExtras) {
    if (extras) this.router.navigate([path], extras);
    else this.router.navigate([path]);
  }

  private initConfigRoute(config: IAuthConfig) {
    this.defaultAfterLoginRoute = config.defaultAfterLoginRoute;
    this.defaultRoute = config.defaultRoute;
    this.accessDenied = config.accessDeniedRoute;
    this.forbiddenRoute = config.oidc.config.forbiddenRoute;
    this.unauthorizedRoute = config.oidc.config.unauthorizedRoute;
  }
}
