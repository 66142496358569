import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from 'app/entities/contacts/contact';

/**
 * Pipe che filtra una lista per carattere iniziale
 */
@Pipe({
  name: 'startsWith',
})
export class StartsWithPipe implements PipeTransform {
  transform(values: Contact[], startsWithText: string) {
    if (startsWithText === '') {
      return values;
    }

    const numbers = '0123456789'.split('');
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

    if (startsWithText === '[0-9]') {
      return this.resultsWithNumbersOrLetters(values, numbers);
    }

    if (startsWithText === 'other') {
      const lettersAndNumbers = [
        ...this.resultsWithNumbersOrLetters(values, numbers),
        ...this.resultsWithNumbersOrLetters(values, alphabet),
      ];
      return values.filter((x) => !lettersAndNumbers.includes(x)).sort();
    }

    const newValues = [];
    values.forEach((value) => {
      if (value.contactAlias.toLowerCase().startsWith(startsWithText.toLowerCase())) {
        newValues.push(value);
      }
    });

    return newValues.sort();
  }

  private resultsWithNumbersOrLetters(values, numbersOrLetters): Contact[] {
    const newValues = [];
    values.forEach((value) => {
      numbersOrLetters.forEach((v) => {
        if (value.contactAlias.toLowerCase().startsWith(v.toLowerCase())) {
          newValues.push(value);
        }
      });
    });
    return newValues.sort();
  }
}
