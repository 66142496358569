import { Injectable } from '@angular/core';
import { default as Swal, SweetAlertCustomClass, default as swal } from 'sweetalert2';
import { NotificationType } from '../notification-type';
import { SweetAlertInfo } from './sweet-alert-info';

@Injectable()
export class SweetAlertService {
  customClass: SweetAlertCustomClass = {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-danger',
  };

  public close(): void {
    swal.close();
  }

  public show(data: SweetAlertInfo) {
    const confirmCallback = (result) => {
      if (result.value) if (data.confirmCallback) data.confirmCallback();
    };

    const confirmOrDismissCallback = (result) => {
      if (result.value) {
        if (data.confirmCallback) data.confirmCallback();
      } else if (result.dismiss === swal.DismissReason.cancel) if (data.cancelCallback) data.cancelCallback();
    };

    switch (data.type) {
      case NotificationType.GENERAL:
        Swal.fire({
          title: data.title,
          text: data.message,
          buttonsStyling: false,
          customClass: this.customClass,
        }).then();
        break;

      case NotificationType.INFO:
        Swal.fire({
          title: data.title,
          html: data.message,
          buttonsStyling: false,
          customClass: this.customClass,
          icon: 'info',
        }).then(confirmCallback);
        break;

      case NotificationType.LOADING:
        Swal.fire({
          title: data.title,
          html: data.message,
          showConfirmButton: false,
        }).then();
        break;

      case NotificationType.ERROR:
        Swal.fire({
          title: data.title,
          html: data.message,
          icon: 'error',
          buttonsStyling: false,
          customClass: this.customClass,
          allowOutsideClick: data.allowOutsideClick,
        }).then(confirmCallback);
        break;

      case NotificationType.QUESTION:
        Swal.fire({
          title: data.title,
          html: data.message,
          buttonsStyling: false,
          customClass: this.customClass,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Conferma',
          cancelButtonText: 'Annulla',
        }).then(confirmOrDismissCallback);
        break;

      case NotificationType.SUCCESS:
        Swal.fire({
          title: data.title,
          html: data.message,
          icon: 'success',
          buttonsStyling: false,
          customClass: this.customClass,
          allowOutsideClick: data.allowOutsideClick,
        }).then(confirmOrDismissCallback);
        break;
    }
  }
}
