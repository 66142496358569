<header class="container-fluid">
  <div class="row">
    <!-- NAV SX -->
    <div class="col-md-3 nav-sx">
      <div class="row nav-sx-container">
        <div class="col-md-3 nav-sx-button" *ngIf="!_opened" [@inOutAnimation]>
          <div id="toggle-sidebar-container">
            <button
              type="button"
              id="sidebarCollapseBtn"
              data-testId="sidebarCollapseBtn"
              class="btn sidebar-button"
              aria-label="Apri menù laterale"
              (click)="_toggleSidebar()"
            >
              <i class="{{ faIcons.FAS_BARS }} {{ faIcons.FA_2X }}"></i>
              <p class="mb-0">MENU</p>
            </button>
          </div>
        </div>
        <div class="col-md-3">
          <a [routerLink]="'/gaw/home'" class="nav-home">
            <img src="assets/icon/solid/no-circle/misc-home.svg" alt="Vai alla home" />
          </a>
        </div>
        <div class="col-md-6">
          <div class="ctel-logo-container">
            <img src="/assets/images/logo-ctel.svg" alt="" class="ctel-logo" />
          </div>
        </div>
      </div>
    </div>
    <!-- SMALL SEARCH BAR, VISIBILE SOLO SE NON SIAMO NELLA HOME -->
    <div class="col-md-6">
      <ng-container
        *ngIf="
          (!route.includes('home') || route.includes('gawfe/home') || route.includes('gaw30/home')) &&
          !route.includes('/gaw/favorite/searches/')
        "
      >
        <ng-content select="[searchbar-small]"></ng-content>
      </ng-container>
    </div>
    <!-- NAV DX -->
    <div class="col-md-3 nav-dx">
      <div class="row">
        <div class="nav-item-dropdown col-lg-7 col-12">
          <gaw-companies-dropdown></gaw-companies-dropdown>
        </div>

        <div class="header-icons-container nav-dx-item col-lg-5 col-12">
          <a
            type="button"
            class="downloads-button dropdown-toggle"
            href="https://credemtel.freshdesk.com/customer/login"
            tooltip="Assistenza Credemtel"
            target="_blank"
            title="Assistenza credemtel"
            aria-label="Vai alla pagina di assistenza clienti di Credemtel"
          >
            <i class="fa fa-question-circle fa-2x" aria-hidden="true"></i>
          </a>
          <div
            class="nav-item-dropdown"
            dropdown
            #dropdown="bs-dropdown"
            *ngIf="(showUploadIcon$ | async) === true"
            [autoClose]="true"
            [insideClick]="true"
            tooltip="Uploads"
            placement="bottom"
          >
            <button dropdownToggle aria-label="Visualizza gli upload" class="btn-ghost uploads-button dropdown-toggle">
              <img src="/assets/homepage/download.svg" style="transform: rotate(180deg)" alt="" />
              <ng-container *ngIf="hasActiveUploads$ | async">
                <span class="dot_button_badge">&nbsp;</span>
              </ng-container>
            </button>
            <div
              id="dropdown-triggers-uploads-manual"
              *dropdownMenu
              class="dropdown-menu dropdown-menu-right"
              role="menu"
              aria-labelledby="btn-triggers-manual"
            >
              <gaw-upload-notifications [uploadNotifications]="uploadResults$ | async"></gaw-upload-notifications>
            </div>
          </div>
          <div
            class="nav-item-dropdown"
            dropdown
            #dropdown="bs-dropdown"
            [insideClick]="true"
            tooltip="Downloads"
            placement="bottom"
          >
            <button
              dropdownToggle
              aria-label="Visualizza i download"
              class="btn-ghost downloads-button dropdown-toggle"
            >
              <img src="/assets/homepage/download.svg" alt="" />
              <ng-container *ngIf="unreadDownloads$ | async">
                <span class="dot_button_badge" [ngClass]="{ dot_button_badge_highlight: downloadHighlighted$ | async }"
                  >&nbsp;</span
                >
              </ng-container>
            </button>
            <div
              id="dropdown-triggers-downloads-manual"
              *dropdownMenu
              class="dropdown-menu dropdown-menu-right"
              role="menu"
              aria-labelledby="btn-triggers-manual"
            >
              <tm-transfer-manager></tm-transfer-manager>
            </div>
          </div>
          <div
            class="nav-item-dropdown"
            dropdown
            #dropdown="bs-dropdown"
            tooltip="Notifiche"
            placement="bottom"
            [autoClose]="true"
          >
            <button
              dropdownToggle
              aria-label="Visualizza le notifiche"
              class="btn-ghost notifications-button dropdown-toggle"
            >
              <img src="/assets/homepage/notification.svg" alt="" />
              <ng-container *ngIf="unreadNotificationsCount$ | async as unreadNotificationsCount">
                <span
                  class="button_badge"
                  [ngClass]="{ button_badge_highlight: notificationCountHighlighted$ | async }"
                >
                  {{ unreadNotificationsCount }}
                </span>
              </ng-container>
            </button>
            <ul
              id="dropdown-triggers-manual"
              *dropdownMenu
              class="dropdown-menu dropdown-menu-right"
              role="menu"
              aria-labelledby="btn-triggers-manual"
            >
              <ng-container *ngIf="newsAndNotifications$ | async as newsNotifications; else noNotifications">
                <ng-container *ngIf="newsNotifications.length > 0; else noNotifications">
                  <ng-container *ngFor="let newsNotification of newsNotifications; let i = index">
                    <li role="menuitem" class="notification">
                      <button
                        class="dropdown-item"
                        aria-label="Leggi notizia intera"
                        [ngClass]="{
                          unread: !newsNotification.read,
                          'is-read': newsNotification.read,
                          'li-rounded-top': i === 0,
                          'li-rounded-bottom': i === newsNotifications.length - 1,
                        }"
                        (click)="setNewsNotificationRead(newsNotification)"
                      >
                        <div *ngIf="newsNotification.read === false" class="new-alert">New!</div>
                        <span
                          class="shorten-message"
                          *ngIf="newsNotification.type === 'news'"
                          [innerHtml]="newsNotification.title | shorten: '200'"
                        ></span>
                        <span
                          class="shorten-message"
                          *ngIf="newsNotification.type === 'notification'"
                          [innerHtml]="newsNotification.message | shorten: '200'"
                          gawRouteTransformer
                        ></span>
                      </button>
                    </li>
                    <li
                      *ngIf="newsNotification && newsNotifications.length !== i + 1"
                      class="dropdown-divider divider-notification"
                    ></li>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-template #noNotifications>
                <li role="menuitem" class="notification">
                  <a class="dropdown-item is-read li-rounded-top li-rounded-bottom">
                    <span class="shorten-message"><b>Non ci sono notifiche</b></span>
                  </a>
                </li>
              </ng-template>
            </ul>
          </div>
          <div
            class="nav-item-dropdown"
            dropdown
            #dropdown="bs-dropdown"
            [autoClose]="true"
            [insideClick]="true"
            tooltip="Profilo"
            placement="bottom"
          >
            <button
              dropdownToggle
              aria-label="Visualizza dettagli utente"
              class="btn-ghost profile-button dropdown-toggle"
            >
              <img src="/assets/homepage/avatar.svg" alt="" />
            </button>
            <ul
              id="dropdown-triggers-manual"
              *dropdownMenu
              class="dropdown-menu dropdown-menu-right"
              role="menu"
              aria-labelledby="btn-triggers-manual"
            >
              <li class="profile-menu-item">
                <div class="dropdown-item user-logo-block" [routerLink]="'/user/detail/my-data'">
                  <img class="user-logo" src="/assets/images/user.png" alt="" />
                  <div *ngIf="user" id="user-info">
                    <span>{{ user.firstName }}</span>
                    <span> {{ user.lastName }}</span>
                  </div>
                </div>
              </li>
              <li class="dropdown-divider divider-profile"></li>
              <li class="profile-menu-item">
                <a class="dropdown-item logout" href="{{ changePasswordUrl$ | async }}" target="_blank">
                  <span class="navigation-item"> CAMBIA PASSWORD </span>
                </a>
              </li>
              <li class="dropdown-divider divider-profile"></li>
              <li class="profile-menu-item">
                <button class="dropdown-item logout" (click)="logout()">
                  <span class="navigation-item"> LOGOUT </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="route.includes('home') && !route.includes('gawfe/') && !route.includes('gaw30/')">
    <ng-content select="[searchbar]"></ng-content>
  </ng-container>
  <ng-container *ngIf="route.includes('home') && !route.includes('gawfe/') && !route.includes('gaw30/')">
    <ng-content select="[searchbar]"></ng-content>
  </ng-container>
</header>
