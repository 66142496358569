<table *ngIf="data">
  <tr>
    <th>Codice raccomandata</th>
  </tr>
  <ng-container *ngIf="data.trackingResult.links.length === 0">
    <tr>
      <td>Nessun numero raccomandata per il documento selezionato.</td>
    </tr>
  </ng-container>
  <ng-container *ngFor="let link of data.trackingResult.links">
    <tr>
      <td>
        <a
          *ngIf="link.link; else nolink"
          [attr.aria-label]="link.toolTip"
          href="{{ link.link }}"
          title="{{ link.toolTip }}"
          target="_blank"
          >{{ link.codiceRaccomandata }}</a
        >
        <ng-template #nolink>
          <p style="margin: 0" [attr.aria-label]="link.toolTip" title="{{ link.toolTip }}">
            {{ link.codiceRaccomandata }}
          </p>
        </ng-template>
      </td>
    </tr>
  </ng-container>
</table>
