import { Injectable } from '@angular/core';
import { TransferUtils } from '@ctel/transfer-manager';
import { Transfer } from '@ctel/transfer-manager/lib/entities/transfer';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { FileSaver } from 'app/core/common/utilities/file-saver/file-saver';
import { FileSaverExtension } from 'app/core/common/utilities/file-saver/file-saver-extension.enum';
import { EMPTY, Observable } from 'rxjs';
import { catchError, filter, take, tap } from 'rxjs/operators';
import { ActionsHttpService } from '../actions-http.service';

@Injectable({
  providedIn: 'root',
})
export class SingleDocumentCallback {
  constructor(private actionsHttpService: ActionsHttpService) {}

  public downloadPDF(url: string, progSpool: string, progBusta: string, docHash: string): Observable<unknown> {
    return this.actionsHttpService.whenSinglePdf(url, progSpool, progBusta, docHash).pipe(
      catchError((err: unknown) => {
        if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
          //this.router.navigate(['/unauthorized']).then();
        }
        return EMPTY;
      }),
      take(1),
      tap((result) => {
        if (result !== null) {
          const r = result as Transfer;
          if (TransferUtils.isHttpResponse(r.originatingEvent)) {
            const blob = new Blob([r.originatingEvent.body], { type: 'application/pdf' });
            new FileSaver(blob).saveAs(r.name, FileSaverExtension.PDF);
          }
        }
      }),
    );
  }

  public downloadAttachments(url: string, progSpool: string, progBusta: string, docHash: string): Observable<unknown> {
    return this.actionsHttpService.whenAttachments(url, progSpool, progBusta, docHash).pipe(
      catchError((err: unknown) => {
        if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
          //this.router.navigate(['/unauthorized']).then();
        }
        return EMPTY;
      }),
      take(1),
      tap((result) => {
        if (result !== null) {
          const r = result as Transfer;
          if (TransferUtils.isHttpResponse(r.originatingEvent)) {
            const blob = new Blob([r.originatingEvent.body], { type: 'application/zip' });
            new FileSaver(blob).saveAs(r.name, FileSaverExtension.ZIP);
          }
        }
      }),
    );
  }

  public downloadXML(url: string, progSpool: string, progBusta: string): Observable<unknown> {
    return this.actionsHttpService.whenSingleXml(url, progSpool, progBusta).pipe(
      catchError((err: unknown) => {
        if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
          //this.router.navigate(['/unauthorized']).then();
        }
        return EMPTY;
      }),
      take(1),
      tap((result) => {
        if (result !== null) {
          const r = result as Transfer;
          if (TransferUtils.isHttpResponse(r.originatingEvent)) {
            const blob = new Blob([r.originatingEvent.body], { type: 'application/xml' });
            new FileSaver(blob).saveAs(r.name, FileSaverExtension.XML);
          }
        }
      }),
    );
  }

  public downloadEsiti(url: string, progSpool: string, progBusta: string): Observable<unknown> {
    return this.actionsHttpService.whenDownloadPDF(url, progSpool, progBusta).pipe(
      catchError((err: unknown) => {
        return EMPTY;
      }),
      take(1),
      filter((result) => result !== null),
      tap((result) => {
        const r = result as Transfer;
        if (TransferUtils.isHttpResponse(r.originatingEvent)) {
          const blob = new Blob([r.originatingEvent.body], { type: 'application/pdf' });
          new FileSaver(blob).saveAs(r.name, FileSaverExtension.PDF);
        }
      }),
    );
  }
}
