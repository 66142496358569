/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { UserService } from '@ctel/auth';
import { TreeNode } from '@ctel/folders-tree';
import { GraphicInfoResponse, InfoByDocSeries } from '@ctel/gaw-commons';
import { DocumentsHttpService, DocumentsSeriesService } from '@ctel/gaw30-commons';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { SearchPagesService } from 'app/core/business/search-pages/search-pages.service';
import { PermissionService } from 'app/core/business/user/permissions/permission.service';
import { AppErrorBuilder, ErrorTypes } from 'app/core/common/error';
import { Breadcrumb } from 'app/entities/folder/breadcrumb';
import { DocInFolder } from 'app/entities/folder/doc-in-folder.interface';
import {
  BehaviorDetails,
  DeleteDocumentInFolder,
  DeleteFolder,
  DeleteMultipleFolder,
  ExportExcel,
  ExportExcelItem,
  ExportZip,
  ExportZipItem,
  InsertDocumentInFolder,
  InsertFolder,
  PagingDetails,
  RemoveMultipleDocuments,
  SearchInFolderContext,
  UpdateDocumentInFolder,
  UpdateFolder,
} from 'app/entities/folder/folder-request';
import {
  AssociateDocInFolderResponse,
  FoldersResponse,
  LogStati,
  SearchData,
  SearchFolderAndDocumentResult,
} from 'app/entities/folder/folder-response';
import { Folder } from 'app/entities/folder/folder.interface';
import { SearchContext } from 'app/entities/search-context/search-context';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { SearchContextHttpService } from 'app/modules/homepage/core/search-context/search-context-http.service';
import { Observable, ReplaySubject, Subject, combineLatest, firstValueFrom, forkJoin, of } from 'rxjs';
import { catchError, delay, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { OrphansCount } from '../../../../entities/orphans-count/orphans-count';
import { SingleSection } from '../../../../entities/sections/sections';
import { FoldersHttpService } from './folders-http.service';
import { FolderListEntity } from './folders-list/foldersListEntity';
import { FileFolders } from './folders-tree-view/oldTree.model';

@Injectable({
  providedIn: 'root',
})
export class FoldersService {
  private static ENTITY_TYPE_FASCICOLO = 'Fascicolo';
  private static ORFANI = 'ORFANI';

  private listNameSingleActions = [
    //"Associa Documenti",
    'Carica documenti',
    'Dettaglio fascicolo',
    'Scarica',
    'Elimina',
    'Modifica',
    'Storico Modifiche',
  ];

  private listNameSingleActionsForDocument = [
    'Associa un Fascicolo',
    'Assegna Data di Scadenza',
    'Visualizza',
    'Lista Fascicoli',
    'Scarica',
    'Rimuovi da Fascicolo',
    'Storico Modifiche',
  ];

  private listNameMultiMassiveActions = ['Scarica', 'Esporta Excel', 'Rimuovi da fascicolo', 'Associa a fascicoli'];

  private destroy$ = new Subject<void>();
  private folders$ = new ReplaySubject<Folder[]>(1);
  currentFolders$ = this.folders$.asObservable();

  private rootFolders$ = new ReplaySubject<Folder[]>(1);
  currentRootFolders$ = this.folders$.asObservable();

  private selectedFolderId$ = new ReplaySubject<string>(1);
  currentSelectedFolderId$ = this.selectedFolderId$.asObservable();

  private selectedFolder$ = new ReplaySubject<Folder>(1);
  currentSelectedFolder$ = this.selectedFolder$.asObservable();

  private selectedChildren$ = new ReplaySubject<FileFolders[]>(1);
  currentSelectedChildren$ = this.selectedChildren$.asObservable();

  private breadcrumbs = new ReplaySubject<Breadcrumb[]>(1);
  currentBreadcrumbs$ = this.breadcrumbs.asObservable();

  private singleActions$ = new ReplaySubject<ActionObject[]>(1);
  currentSingleActions$ = this.singleActions$.asObservable();

  private singleActionsForDocument$ = new ReplaySubject<ActionObject[]>(1);
  currentSingleActionsForDocument$ = this.singleActionsForDocument$.asObservable();

  private multiMassiveActions$ = new ReplaySubject<ActionObject[]>(1);
  currentMultiMassiveActions$ = this.multiMassiveActions$.asObservable();

  private docs$ = new ReplaySubject<DocInFolder[]>(1);
  currentDocs$ = this.docs$.asObservable();

  private resultSearchForGrid$ = new ReplaySubject<SearchFolderAndDocumentResult[]>(1);
  currentResultSearchForGrid$ = this.resultSearchForGrid$.asObservable();

  private showResultSearch$ = new ReplaySubject<boolean>(1);
  currentShowResultSearch$ = this.showResultSearch$.asObservable();

  private searchText$ = new ReplaySubject<string>(1);
  currentSearchText$ = this.searchText$.asObservable();

  private loadingFolderGrid$ = new ReplaySubject<boolean>(1);
  currentLoadingFolderGrid$ = this.loadingFolderGrid$.asObservable();

  private allIdSerieDoc$ = new ReplaySubject<string[]>(1);
  currentAllIdSerieDoc$ = this.allIdSerieDoc$.asObservable();

  hasFascicolo$: Observable<boolean>;

  private _allFolders: Folder[] = [];
  private _rootFolders: Folder[];
  private _allIdSerieDoc: string[] = [];
  private _selectedIdSerieDoc: string;
  private _selectedChildren: FileFolders[] = [];
  private _selectedNode: string;
  private _searchContext: SearchContext[];
  private _selectedFolderId: string;
  private _selectedDocSeries: InfoByDocSeries;
  private _graphicInfos: GraphicInfoResponse[] = [];
  private _docSeriesIdFascicolo: string;
  private _ancestors: Folder[];
  private _showResultSearch = false;
  private _searchText = '';
  private _docs: DocInFolder[] = [];

  constructor(
    private documentsHttpService: DocumentsHttpService,
    private documentsSeriesService: DocumentsSeriesService,
    private foldersHttpService: FoldersHttpService,
    private companiesService: CompaniesService,
    private searchContextHttpService: SearchContextHttpService,
    private searchPagesService: SearchPagesService,
    private userService: UserService,
    private permissionService: PermissionService,
  ) {
    this.companiesService
      .whenCurrentCompany()
      .pipe(switchMap((cc) => this.searchContextHttpService.getSearchContext(cc.licenseId, cc.siaCode, 'GAW30')))
      .subscribe((val) => {
        this._searchContext = val;
        val.forEach((element) => {
          const docSeriesIds = <Array<string>>element.docSeriesIds;
          docSeriesIds.forEach((id) => {
            if (this._allIdSerieDoc.indexOf(id) === -1) {
              this._allIdSerieDoc.push(id);
            }
          });
          this.allIdSerieDoc$.next(this._allIdSerieDoc);
        });
      });

    combineLatest([
      this.userService.getUser(),
      this.companiesService.whenCurrentCompany(),
      this.permissionService.whenPermissions(),
    ])
      .pipe(take(1))
      .subscribe(([, , permissions]) => {
        if (permissions.find((c) => c === 'Gaw.Azioni.Cestino')) {
          const moveToTrash = 'Sposta nel Cestino';
          this.listNameSingleActionsForDocument.push(moveToTrash);
          this.listNameMultiMassiveActions.push(moveToTrash);
        }

        let tempActions = [];
        this.listNameSingleActions.forEach((name) => {
          const action = new ActionObject();
          action.type = 'singleAction';
          action.name = name;
          action.highlighted = false;
          action.icon = '';

          switch (name) {
            case 'Carica documenti':
              action.icon = 'fas fa-upload';
              break;
            case 'Scarica':
              action.icon = 'fas fa-download';
              break;
            case 'Storico Modifiche':
            case 'Dettaglio fascicolo':
              action.icon = 'far fa-eye';
              break;
            case 'Elimina':
              action.icon = 'fa fa-trash';
              break;
            case 'Modifica':
              action.icon = 'fas fa-pen';
              break;
            default:
              break;
          }

          tempActions.push(action);
        });
        this.setSingleActions(tempActions);

        tempActions = [];
        this.listNameSingleActionsForDocument.forEach((name) => {
          const action = new ActionObject();

          action.type = 'singleAction';
          action.name = name;
          action.highlighted = false;
          action.icon = '';

          switch (name) {
            case 'Scarica':
              action.icon = 'fas fa-download';
              action.actionCode = ActionCode.DOWNLOAD_PDF;
              action.url =
                '/v1/Document/DisplayDocument?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&download=true';
              break;
            case 'Storico Modifiche':
            case 'Visualizza':
              action.icon = 'far fa-eye';
              break;
            case 'Associa un Fascicolo':
              action.icon = 'fas fa-link';
              break;
            case 'Rimuovi da Fascicolo':
              action.icon = 'fas fa-unlink';
              break;
            case 'Lista Fascicoli':
              action.icon = 'fas fa-folder-open';
              break;
            case 'Assegna Data di Scadenza':
              action.icon = 'far fa-calendar-times';
              break;
            case 'Sposta nel Cestino':
              action.actionCode = ActionCode.MOVE_TO_TRASH;
              action.icon = 'far fa-trash-alt';
              action.highlighted = true;
              action.url = '/Trash/command/MoveToTrash';
              break;
          }

          tempActions.push(action);
        });
        this.setSingleActionsForDocument(tempActions);

        tempActions = [];
        this.listNameMultiMassiveActions.forEach((name) => {
          const action = new ActionObject();

          action.type = 'multipleAction';
          action.name = name;
          action.highlighted = false;
          action.icon = '';

          switch (name) {
            case 'Scarica':
              action.icon = 'fas fa-download';
              break;
            case 'Esporta Excel':
              action.icon = 'fas fa-file-excel';
              break;
            case 'Associa a fascicoli':
              action.icon = 'fas fa-link';
              break;
            case 'Rimuovi da fascicolo':
              action.icon = 'fas fa-unlink';
              break;
            case 'Sposta nel Cestino':
              action.actionCode = ActionCode.MOVE_TO_TRASH;
              action.icon = 'far fa-trash-alt';
              action.highlighted = true;
              action.url = '/Trash/command/MoveToTrash';
              break;
          }

          tempActions.push(action);
        });
        this.setMultiMassiveActions(tempActions);
      });

    this.foldersHttpService.currentIdsSerieDocFascicolo$
      .pipe(
        tap((ids) => {
          if (ids !== undefined && ids.length > 0) {
            this.loadingFolderGrid$.next(true);

            if (this._rootFolders && this._rootFolders.length > 0) {
              this.loadingFolderGrid$.next(false);
            }

            if (this._graphicInfos.length === 0) {
              ids.forEach((id) => {
                this.documentsHttpService.whenGraphic(id).subscribe((graphicInfo) => {
                  if (this._graphicInfos.filter((g) => g.id === graphicInfo.id).length === 0) {
                    this._graphicInfos.push(graphicInfo);
                  }
                });
              });
            }
          }
        }),
        mergeMap((ids) => {
          if (ids !== undefined && ids.length > 0) {
            return this.foldersHttpService.whenAllFolders();
          }
          return of([]);
        }),
      )
      .subscribe((folders) => {
        this.folders$.next(folders);
        this._rootFolders = folders.filter((folder) => folder.parentFolderId === null);
        this.rootFolders$.next(this._rootFolders);
        this.addFoldersToCurrentAllFolders(folders);
        this.loadingFolderGrid$.next(false);
      });

    this.searchPagesService.currentResultSearchForGrid.subscribe((resulSearchForGrid) => {
      this.resultSearchForGrid$.next(resulSearchForGrid);
    });

    this.searchPagesService.currentShowResultSearch.subscribe((showResultSearch) => {
      this.showResultSearch$.next(showResultSearch);
    });

    this.searchPagesService.currentSearchText.subscribe((searchText) => {
      this.searchText$.next(searchText);
    });

    this.searchPagesService.currentLoadingFolderGrid.subscribe((loadingFolderGrid) => {
      this.loadingFolderGrid$.next(loadingFolderGrid);
    });

    this.hasFascicolo$ = this.documentsSeriesService
      .whenDocumentsSeries()
      .pipe(map((docSeries: SingleSection[]) => docSeries.some((d) => d.sectionCode === 'gaw30_fascicoli')));
  }

  get docSeriesIdFascicolo() {
    return this._docSeriesIdFascicolo;
  }

  set docSeriesIdFascicolo(docSeriesIdFascicolo: string) {
    this._docSeriesIdFascicolo = docSeriesIdFascicolo;
  }

  get selectedNode() {
    return this._selectedNode;
  }

  set selectedNode(node: string) {
    this._selectedNode = node;
  }

  get graphicInfos() {
    return this._graphicInfos;
  }

  get allFolders() {
    return this._allFolders;
  }

  get showResultSearch() {
    return this._showResultSearch;
  }

  get searchText() {
    return this._searchText;
  }

  get docs(): DocInFolder[] {
    return this._docs;
  }

  get selectedFolderId() {
    return this._selectedFolderId;
  }

  set selectedFolderId(folderId: string) {
    if (folderId !== this._selectedFolderId) {
      this._selectedFolderId = folderId;
      this.selectedFolderId$.next(folderId);
      this.searchPagesService.setSelectedFolderId(folderId);
      this.setShowResultSearch(false);
    }
  }

  get selectedIdSerieDoc() {
    return this._selectedIdSerieDoc;
  }

  set selectedIdSerieDoc(selectedIdSerieDoc: string) {
    this._selectedIdSerieDoc = selectedIdSerieDoc;
  }

  get ancestors() {
    return this._ancestors;
  }

  set ancestors(ancestors: Folder[]) {
    this._ancestors = ancestors;
  }

  get selectedDocSeries() {
    return this._selectedDocSeries;
  }

  set selectedDocSeries(selectedDocSeries: InfoByDocSeries) {
    this._selectedDocSeries = selectedDocSeries;
  }

  get idSerieDocFascicolo() {
    return this.foldersHttpService.idsSerieDocFascicolo;
  }

  reloadRootFolders() {
    this.foldersHttpService
      .whenAllFolders()
      .pipe(take(1))
      .subscribe((folders) => {
        this.folders$.next(folders);
        this._rootFolders = folders.filter((folder) => folder.parentFolderId === null);
        this.rootFolders$.next(this._rootFolders);
        this.addFoldersToCurrentAllFolders(folders);
        this.loadingFolderGrid$.next(false);
      });
  }

  whenOrphansCount(folderId: string): Observable<OrphansCount> {
    return this.foldersHttpService.whenOrphansCount(folderId, this._allIdSerieDoc);
  }

  whenFolderAncestors(folderId: string, idSerieDoc: string): Observable<Folder[]> {
    return this.foldersHttpService.whenFolderAncestors(folderId, idSerieDoc);
  }

  getIdSerieDocFascicoloByFolderIdInCurrentAllFolder(folderId: string): string {
    return this._allFolders.filter((f) => f.id === folderId)[0]?.idSerieDocFascicolo;
  }

  retrieveIdSerieDocFascicolo() {
    return this.getIdSerieDocFascicoloByFolderIdInCurrentAllFolder(this._allFolders[0]?.id);
  }

  getNameByFolderIdInCurrentAllFolder(folderId: string): string {
    return this._allFolders.filter((f) => f.id === folderId)[0]?.name;
  }

  getChildrenOfFolder(folderId: string, idSerieDocFascicolo: string): Observable<Folder[]> {
    return this.foldersHttpService.whenChildrenInFolder(folderId, idSerieDocFascicolo).pipe(
      map((response) => {
        if (response.isSuccessful) {
          const sortedFolders = (response.data as object[]).map(
            (el) =>
              ({
                ...el,
                id: el['id'].toString(),
                parentFolderId: el['parentFolderId'] ? el['parentFolderId'].toString() : null,
                idSerieDoc: el['idSerieDoc'] ? el['idSerieDoc'].toString() : null,
                idSerieDocFascicolo: el['idSerieDocFascicolo'] ? el['idSerieDocFascicolo'].toString() : null,
              }) as Folder,
          );
          return sortedFolders.sort((a, b) => a.name.localeCompare(b.name));
        } else {
          return [];
        }
      }),
    );
  }

  whenFoldersAndDocs(folderId: string, idSerieDoc: string) {
    if (folderId) {
      return this.whenFolderAncestors(folderId, idSerieDoc).pipe(
        tap((ancestors) => (this._ancestors = ancestors)),
        mergeMap((ancestors) =>
          combineLatest({
            childrenByParent:
              ancestors && ancestors.length > 0
                ? forkJoin(ancestors.map((a) => this.getChildrenOfFolder(a.id, a.idSerieDocFascicolo)))
                : of([] as Folder[][]),
            documents: this.foldersHttpService.whenDocsInFolder(folderId, this._allIdSerieDoc).pipe(
              map((response) => <Array<DocInFolder>>response.data),
              tap((docs) => {
                this._docs = docs ?? [];
                this.docs$.next(this.docs);
              }),
            ),
          }),
        ),
      );
    }

    return of({ childrenByParent: [] as Folder[][], documents: [] as DocInFolder[] });
  }

  whenDocs(folderId: string) {
    return this.foldersHttpService.whenDocsInFolder(folderId, this._allIdSerieDoc).pipe(
      map((response) => <Array<DocInFolder>>response.data),
      tap((docs) => {
        this._docs = docs ?? [];
        this.docs$.next(this.docs);
      }),
    );
  }

  whenOrphanDocs(folderId: string) {
    if (folderId) {
      return this.foldersHttpService.whenDocsInFolder(folderId, this._allIdSerieDoc).pipe(
        map((response) => <Array<DocInFolder>>response.data),
        tap((docs) => {
          this._docs = docs ?? [];
          this.docs$.next(this.docs);
        }),
      );
    }

    return of([] as DocInFolder[]);
  }

  reloadDocs() {
    this.foldersHttpService
      .whenDocsInFolder(this._selectedFolderId, this._allIdSerieDoc)
      .pipe(take(1))
      .subscribe((response) => {
        this._docs = <Array<DocInFolder>>response.data;
        this.docs$.next(this.docs);
        this.selectedFolderId$.next(this._selectedFolderId);
      });
  }

  getLevelOfSelectedFolderId<T>(node?: TreeNode<T>) {
    let id = node ? node.id : this._selectedFolderId;
    let count = 0;
    while (id) {
      const filtered = this._allFolders.filter((folder) => folder.id === id);
      id = filtered[0]?.parentFolderId;
      count++;
    }
    return count;
  }

  getIdFoldersPathOfSelectedFolderId() {
    let id = this._selectedFolderId;
    let path = [];
    while (id) {
      const filtered = this._allFolders.filter((folder) => folder.id === id);
      path = [id, ...path];
      id = filtered[0]?.parentFolderId;
    }
    return path;
  }

  setSelectedChildren(children: FileFolders[]): void {
    this.selectedChildren$.next(children);
  }

  setCurrentBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
    this.breadcrumbs.next(breadcrumbs);
  }

  setSingleActions(actions: ActionObject[]): void {
    this.singleActions$.next(actions);
  }

  setSingleActionsForDocument(actions: ActionObject[]): void {
    this.singleActionsForDocument$.next(actions);
  }

  setResultSearchForGrid(resultSearchForGrid: SearchFolderAndDocumentResult[]) {
    this.resultSearchForGrid$.next(resultSearchForGrid);
  }

  setShowResultSearch(showResultSearch: boolean) {
    this.showResultSearch$.next(showResultSearch);
  }

  addFoldersToCurrentAllFolders(folders: Folder[]): void {
    const foldersSet = new Set<Folder>(this._allFolders ?? []);
    folders.forEach((f) => foldersSet.add(f));
    this._allFolders = Array.from(foldersSet);
  }

  setMultiMassiveActions(actions: ActionObject[]): void {
    this.multiMassiveActions$.next(actions);
  }

  insertNewSelectedChildren(newChild: FileFolders) {
    this._selectedChildren.push(newChild);
    this.selectedChildren$.next(this._selectedChildren);
  }

  updateChildInSelectedChildren(id: string, newName: string) {
    for (const folder of this._selectedChildren) {
      if (folder.code === id) {
        folder.name = newName;
      }
    }

    this.selectedChildren$.next(this._selectedChildren);
  }

  updateFolderCurrentAllFolders(id: string, newName: string, newDescription: string) {
    const folderIndex = this._allFolders.findIndex((i) => i.id === id);
    if (folderIndex > -1) {
      this._allFolders[folderIndex].name = newName;
      this._allFolders[folderIndex].description = newDescription;
    }
  }

  deleteFolderCurrentAllFolders(id: string) {
    const folderIndex = this._allFolders.findIndex((i) => i.id === id);
    if (folderIndex > -1) {
      this._allFolders.splice(folderIndex, 1);
    }
  }

  deleteChildInSelectedChildren(id: string) {
    const folderIndex = this._allFolders.findIndex((i) => i.id === id);
    if (folderIndex > -1) {
      this._allFolders.splice(folderIndex, 1);
    }

    for (let i = 0; i < this._selectedChildren.length; i++) {
      if (this._selectedChildren[i].code === id) {
        this._selectedChildren.splice(i, 1);
      }
    }

    this.selectedChildren$.next(this._selectedChildren);
  }

  getEntireIdSerieDocFascicolo() {
    return this.foldersHttpService.idsSerieDocFascicolo;
  }

  getFolderById(folderID: string, licenseId: string, siaCode: string): Observable<FoldersResponse<Folder>> {
    const temp = this.getIdSerieDocFascicoloByFolderIdInCurrentAllFolder(folderID);
    if (temp) {
      return this.foldersHttpService.getFolder(folderID, temp, licenseId, siaCode);
    }
    return of(undefined);
  }

  getFolder(folderId: string, idSerieDoc: string): Observable<Folder> {
    return this.companiesService.whenCurrentCompany().pipe(
      switchMap((company) =>
        this.foldersHttpService.getFolder(folderId, idSerieDoc, company.licenseId, company.siaCode),
      ),
      map((response) => {
        if (!response.isSuccessful) {
          throw new AppErrorBuilder(ErrorTypes.GET_FAILURE)
            .description('Errore durante la richiesta della folder')
            .build();
        }

        return response.data as Folder;
      }),
    );
  }

  getIdSerieDocFascicoloByFolderID(folderID: string): string {
    const temp = this.getIdSerieDocFascicoloByFolderIdInCurrentAllFolder(folderID);
    if (temp) {
      return temp;
    }
  }

  folderHasDocuments(folderID: string): Observable<boolean> {
    return this.foldersHttpService.getFolderHasDocuments(folderID, this._allIdSerieDoc);
  }

  insertFolder(name: string, description: string, idSerieDocFascicolo: string) {
    const insertFolder = new InsertFolder();
    insertFolder.parentFolderId = this._selectedFolderId !== '' ? Number.parseInt(this._selectedFolderId) : null;
    insertFolder.name = name;
    insertFolder.description = description === undefined ? '' : description;
    // insertFolder.metadataAssociation = "and"; //TODO da modificare
    insertFolder.idSerieDoc = null; // TODO da modificare
    insertFolder.idSerieDocFascicolo = Number.parseInt(idSerieDocFascicolo);
    insertFolder.licenseId = this.companiesService.getCurrentCompanyValue().licenseId;
    insertFolder.siaCode = this.companiesService.getCurrentCompanyValue().siaCode;
    return firstValueFrom(this.foldersHttpService.insertFolder(insertFolder));
  }

  insertFolderInline(name: string, description: string, idSerieDocFascicolo: string, parentFolderId: string) {
    const insertFolder = new InsertFolder();
    insertFolder.parentFolderId = parentFolderId !== '' ? Number.parseInt(parentFolderId) : null;
    insertFolder.name = name;
    insertFolder.description = description === undefined ? '' : description;
    // insertFolder.metadataAssociation = "and"; //TODO da modificare
    insertFolder.idSerieDoc = null; // TODO da modificare
    insertFolder.idSerieDocFascicolo = Number.parseInt(idSerieDocFascicolo);
    insertFolder.licenseId = this.companiesService.getCurrentCompanyValue().licenseId;
    insertFolder.siaCode = this.companiesService.getCurrentCompanyValue().siaCode;
    return firstValueFrom(this.foldersHttpService.insertFolder(insertFolder));
  }

  updateFolder(folder: FolderListEntity) {
    const updateFolder = new UpdateFolder();
    updateFolder.folderId = Number.parseInt(folder.id);
    updateFolder.parentFolderId = Number.parseInt(this._selectedFolderId);
    updateFolder.name = folder.name;
    updateFolder.description = folder.description === undefined ? '' : folder.description;
    // updateFolder.metadataAssociation = "and"; // TODO da modificare
    updateFolder.idSerieDoc = Number.parseInt(folder.idSerieDoc);
    updateFolder.idSerieDocFascicolo = Number.parseInt(folder.idSerieDoc);
    updateFolder.licenseId = this.companiesService.getCurrentCompanyValue().licenseId;
    updateFolder.siaCode = this.companiesService.getCurrentCompanyValue().siaCode;
    return this.foldersHttpService.updateFolder(updateFolder).pipe(delay(500));
  }

  deleteFolder(folderId) {
    const deleteFolder = new DeleteFolder();
    const temp = this._allFolders.filter((f) => f.id === folderId)[0];
    deleteFolder.folderId = Number.parseInt(folderId);
    deleteFolder.idSerieDocFascicolo = Number.parseInt(temp.idSerieDocFascicolo);
    deleteFolder.licenseId = this.companiesService.getCurrentCompanyValue().licenseId;
    deleteFolder.siaCode = this.companiesService.getCurrentCompanyValue().siaCode;
    return this.foldersHttpService.deleteFolder(deleteFolder);
  }

  insertDocumentInFolder(
    documents: {
      progSpool: number;
      progBusta: number;
      idSerieDoc: number;
    }[],
    folderId: number,
    expiryDate,
  ): Observable<AssociateDocInFolderResponse[]> {
    const obj = new InsertDocumentInFolder();

    obj.expiryDate = expiryDate === null ? null : expiryDate;
    obj.folderId = folderId;
    obj.licenseId = this.companiesService.getCurrentCompanyValue().licenseId;
    obj.siaCode = this.companiesService.getCurrentCompanyValue().siaCode;
    obj.documents = documents;

    return this.foldersHttpService.insertDocumentInFolder(obj);
  }

  updateDocumentInFolder(
    idSerieDoc: string,
    folderId: string,
    folderDocumentId: number,
    expiryDate: string,
    progBusta: string,
    progSpool: string,
  ) {
    const obj = new UpdateDocumentInFolder();

    obj.expiryDate = expiryDate === null ? '9999-12-31T00:00:00' : expiryDate;
    obj.folderDocumentId = folderDocumentId;
    obj.folderId = Number.parseInt(folderId);
    obj.idSerieDoc = Number.parseInt(idSerieDoc);
    obj.licenseId = this.companiesService.getCurrentCompanyValue().licenseId;
    obj.siaCode = this.companiesService.getCurrentCompanyValue().siaCode;
    obj.progBusta = Number.parseInt(progBusta);
    obj.progSpool = Number.parseInt(progSpool);

    return this.foldersHttpService.updateDocumentInFolder(obj);
  }

  deleteDocumentInFolder(folderDocumentId) {
    const obj = new DeleteDocumentInFolder();
    obj.folderDocumentId = Number.parseInt(folderDocumentId);
    obj.licenseId = this.companiesService.getCurrentCompanyValue().licenseId;
    obj.siaCode = this.companiesService.getCurrentCompanyValue().siaCode;
    return this.foldersHttpService.deleteDocumentInFolder(obj);
  }

  removeMultipleDocumentsFromFolder(folderDocumentIds: number[]) {
    const obj = new RemoveMultipleDocuments();
    obj.folderDocumentId = folderDocumentIds;
    obj.licenseId = this.companiesService.getCurrentCompanyValue().licenseId;
    obj.siaCode = this.companiesService.getCurrentCompanyValue().siaCode;
    return this.foldersHttpService.whenRemoveMultipleDocuments(obj);
  }

  deleteMultipleFolders(folderIds: string[]) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    const obj = new DeleteMultipleFolder();
    const temp = {};
    folderIds.forEach((id) => {
      const filtered = me._allFolders.filter((f) => f.id === id);
      if (filtered.length > 0) {
        temp[id] = filtered[0].idSerieDocFascicolo;
      }
    });
    obj.folderId_IdSerieDocFascicolo = temp;
    obj.licenseId = this.companiesService.getCurrentCompanyValue().licenseId;
    obj.siaCode = this.companiesService.getCurrentCompanyValue().siaCode;
    return this.foldersHttpService.whenDeleteMultipleFolders(obj);
  }

  getFoldersAssociatedWithTheDoc(progSpool: string, progBusta: string, idSerieDoc: string) {
    return this.foldersHttpService.whenFoldersOfDocument(progSpool, progBusta, idSerieDoc);
  }

  getFolderLogStati(folderId: string): Observable<LogStati[]> {
    return this.foldersHttpService.getFolderLogStati(folderId).pipe(map((response) => response.data));
  }

  getFolderDocumentLogStati(folderDocumentId: string): Observable<LogStati[]> {
    return this.foldersHttpService.getFolderDocumentLogStati(folderDocumentId).pipe(map((response) => response.data));
  }

  searchTypeahead(text: string, max?: number, forDialog = false): Observable<SearchData> {
    const searchStringLength = text.trim().length;
    const minSearchStringLength = 3;

    const behaviorDetails = new BehaviorDetails();
    behaviorDetails.onlyFolders = false;
    behaviorDetails.maxItemsNumber = max ?? 5;
    const pagingDetails = new PagingDetails();
    pagingDetails.startIndex = 0;
    // pagingDetails.itemsNumber = 5;

    return this.companiesService.whenCurrentCompany().pipe(
      tap(() => {
        if (searchStringLength < 3) {
          throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
            .description(
              `La stringa di ricerca senza spazi marginali (trim) deve avere una lunghezza >= ${minSearchStringLength}`,
            )
            .info('Stringa di ricerca', text)
            .info('Lunghezza trim', searchStringLength)
            .build();
        }
      }),
      take(1),
      switchMap(() => {
        // const docSeriesId = this.foldersHttpService.getIdSerieDocFascicolo()[0];
        const search = new SearchInFolderContext();
        search.idSerieDocFascicolo = this.foldersHttpService.idsSerieDocFascicolo.map(function (x) {
          return parseInt(x, 10);
        });
        search.searchDataInfos = this._searchContext;
        search.textToSearch = text;
        search.behavior = behaviorDetails;
        search.paging = pagingDetails;
        search.licenseId = this.companiesService.getCurrentCompanyValue().licenseId;
        search.siaCode = this.companiesService.getCurrentCompanyValue().siaCode;
        search.idFascicolo = forDialog ? null : Number.parseInt(this.searchPagesService.getSelectedFolderId());
        search.behavior.onlyFolders = !search.idFascicolo;

        return this.foldersHttpService.searchTypeAhead(search).pipe(
          map((value) => value.data),
          catchError((err: unknown) => {
            throw new AppErrorBuilder(ErrorTypes.GET_FAILURE)
              .cause(err as Error)
              .description('Errore durante la ricerca sui fascicoli.')
              .info('Stringa di ricerca', text)
              .build();
          }),
        );
      }),
    );
  }

  searchAll(text: string, startIndex: number): Observable<SearchData> {
    const searchStringLength = text.trim().length;
    const minSearchStringLength = 3;

    const behaviorDetails = new BehaviorDetails();
    behaviorDetails.onlyFolders = false;
    behaviorDetails.maxItemsNumber = 25;
    const pagingDetails = new PagingDetails();
    pagingDetails.startIndex = 25 * startIndex;
    // pagingDetails.itemsNumber = 0;

    return this.companiesService.whenCurrentCompany().pipe(
      tap(() => {
        if (searchStringLength < 3) {
          throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
            .description(
              `La stringa di ricerca senza spazi marginali (trim) deve avere una lunghezza >= ${minSearchStringLength}`,
            )
            .info('Stringa di ricerca', text)
            .info('Lunghezza trim', searchStringLength)
            .build();
        }
      }),
      take(1),
      switchMap(() => {
        // const docSeriesId = this.foldersHttpService.idSerieDocFascicolo[0];
        const search = new SearchInFolderContext();
        search.idSerieDocFascicolo = this.foldersHttpService.idsSerieDocFascicolo.map(function (x) {
          return parseInt(x, 10);
        });
        search.searchDataInfos = this._searchContext;
        search.textToSearch = text;
        search.behavior = behaviorDetails;
        search.paging = pagingDetails;
        search.licenseId = this.companiesService.getCurrentCompanyValue().licenseId;
        search.siaCode = this.companiesService.getCurrentCompanyValue().siaCode;
        search.idFascicolo = Number.parseInt(this.searchPagesService.getSelectedFolderId());
        return this.foldersHttpService.searchTypeAhead(search).pipe(
          map((value) => value.data),
          catchError((err: unknown) => {
            throw new AppErrorBuilder(ErrorTypes.GET_FAILURE)
              .cause(err as Error)
              .description('Errore durante la ricerca sui fascicoli.')
              .info('Stringa di ricerca', text)
              .build();
          }),
        );
      }),
    );
  }

  isSelectedFolderOrphan() {
    return (
      this._allFolders.find((fol) => fol.id === this._selectedFolderId)?.statoFascicolo.ultimoStato ===
      FoldersService.ORFANI
    );
  }

  exportExcel(entities: FolderListEntity[]) {
    const req = new ExportExcel();
    req.licenseId = this.companiesService.getCurrentCompanyValue().licenseId;
    req.siaCode = this.companiesService.getCurrentCompanyValue().siaCode;
    const items = [];
    entities.forEach((entity) => {
      const item = new ExportExcelItem();
      if (entity.type === FoldersService.ENTITY_TYPE_FASCICOLO) {
        item.type = 0;
        item.id = Number.parseInt(entity.id);
        item.idSerieDoc = Number.parseInt(entity.idSerieDoc);
      } else {
        item.type = 1;
        item.id = entity.idFascicoloDocumenti;
        item.idSerieDoc = Number.parseInt(entity.idSerieDoc);
        // resultSearch.metadata.progSpool + "_" + resultSearch.metadata.progBusta
        item.progSpool = Number.parseInt(entity.id.split('_')[0]);
        item.progBusta = Number.parseInt(entity.id.split('_')[1]);
      }
      items.push(item);
    });
    req.items = items;
    return this.foldersHttpService.whenExportExcel(req);
  }

  exportZip(entities: FolderListEntity[]) {
    const req = new ExportZip();
    req.licenseId = this.companiesService.getCurrentCompanyValue().licenseId;
    req.siaCode = this.companiesService.getCurrentCompanyValue().siaCode;
    const numberArray = [];
    this._allIdSerieDoc.forEach((val) => numberArray.push(parseInt(val)));
    this.allIdSerieDoc$.next(this._allIdSerieDoc);

    req.idSerieDocs = numberArray;
    const items = [];
    entities.forEach((entity) => {
      const item = new ExportZipItem();
      item.idFascicolo = Number.parseInt(entity.id);
      item.idSerieDocFascicolo = Number.parseInt(entity.idSerieDoc);
      items.push(item);
    });
    req.items = items;
    return this.foldersHttpService.whenExportZip(req);
  }

  whenDocSeries(license: string, siacode: string) {
    return this.documentsHttpService.whenDocSeries(license, siacode);
  }

  setIdSerieDocFascicolo(docSeriesIds: string[]) {
    this.foldersHttpService.setIdSerieDocFascicolo(docSeriesIds);
  }
}

export class LogStatiFolder {
  idFascicolo: number;
  stato: LogStatiDetail;
}

export class LogStatiDocument {
  idFascicoloDocumento: number;
  stato: LogStatiDetail;
}

export class LogStatiDetail {
  idUltimoStato: number;
  ultimoStato: string;
  dataStato: Date;
}

export class SearchDoc {
  search: SearchDocDetail;
  paging: PagingDocDetail;
}

export class SearchDocDetail {
  fullText: boolean;
  value: string;
}

export class PagingDocDetail {
  offset: number;
  docsPerPages: number;
}
