import { Injectable } from '@angular/core';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { SingleDocumentCallback } from './callbacks/single-document-callback';

@Injectable({
  providedIn: 'root',
})
export class SingleActionsCatalog {
  public readonly viewDocument = new ActionBuilder(SingleActionsCode.viewDocument, ActionCode.SHOW_PDF)
    .setActionURL(
      '/v1/Document/DisplayDocument?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&download=false',
    )
    .build();

  public readonly editKeys = new ActionBuilder(SingleActionsCode.editKeys, ActionCode.EDIT_KEYS)
    .setActionURL('/v1/Document/UpdateMetadataGaw?update=true')
    .build();

  public readonly editKeysWithoutCheck = new ActionBuilder(SingleActionsCode.editKeysWithoutCheck, ActionCode.EDIT_KEYS)
    .setActionURL('/v1/Document/UpdateMetadataGaw?update=true')
    .build();

  public readonly editKeysDisabled = new ActionBuilder(
    SingleActionsCode.editKeysDisabled,
    ActionCode.EDIT_KEYS_DISABLED,
  ).build();

  public readonly startWorkflow = new ActionBuilder(SingleActionsCode.startWorkflow, ActionCode.START_WORKFLOW).build();

  public readonly pdfDownload = new ActionBuilder(SingleActionsCode.pdfDownload, ActionCode.DOWNLOAD_PDF)
    .setActionURL(
      '/v1/Document/DisplayDocument?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&download=true',
    )
    .setCallback(([url, progSpool, progBusta, docHash]) =>
      this.singleDocumentCallback.downloadPDF(url, progSpool, progBusta, docHash),
    )
    .build();

  public readonly xmlDownload = new ActionBuilder(SingleActionsCode.xmlDownload, ActionCode.DOWNLOAD_XML)
    .setActionURL('/Fatture/{progSpool}/{progBusta}/allegati/download')
    .setCallback(([url, progSpool, progBusta]) => this.singleDocumentCallback.downloadXML(url, progSpool, progBusta))
    .build();

  public readonly esitoDownload = new ActionBuilder(SingleActionsCode.esitoDownload, ActionCode.DOWNLOAD_ESITI)
    .setActionURL('/Fatture/attive/esiti/{progSpool}/{progBusta}/download')
    .setCallback(([url, progSpool, progBusta]) => this.singleDocumentCallback.downloadEsiti(url, progSpool, progBusta))
    .build();

  public readonly downloadAttachmentReceivable = new ActionBuilder(
    SingleActionsCode.downloadAttachmentReceivable,
    ActionCode.DOWNLOAD_ATTACHMENTS_CA,
  )
    .setActionURL(
      '/v1/Document/GEDINVOICE/DownloadAttachments?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}',
    )
    .setCallback(([url, progSpool, progBusta, docHash]) =>
      this.singleDocumentCallback.downloadAttachments(url, progSpool, progBusta, docHash),
    )
    .build();

  public readonly downloadAttachmentPayable = new ActionBuilder(
    SingleActionsCode.downloadAttachmentPayable,
    ActionCode.DOWNLOAD_ATTACHMENTS_CP,
  )
    .setActionURL(
      '/v1/Document/GEDPASSJOIN/DownloadAttachments?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&canale=35',
    )
    .setCallback(([url, progSpool, progBusta, docHash]) =>
      this.singleDocumentCallback.downloadAttachments(url, progSpool, progBusta, docHash),
    )
    .build();

  public readonly multiHideDocumentsAction = new ActionBuilder(
    SingleActionsCode.multiHideDocumentsAction,
    ActionCode.HIDE_DOCUMENTS,
  )
    .setActionURL('/v1/Document/HideAndRestore?hideAndRestoreAction={hide}')
    .build();

  public readonly multiRestoreDocumentsAction = new ActionBuilder(
    SingleActionsCode.multiRestoreDocumentsAction,
    ActionCode.RESTORE_DOCUMENTS,
  )
    .setActionURL('/v1/Document/HideAndRestore?hideAndRestoreAction={hide}')
    .build();
  public readonly addNotes = new ActionBuilder(SingleActionsCode.addNotes, ActionCode.ADD_NOTES).build();
  public readonly downloadHistoryStates = new ActionBuilder(
    SingleActionsCode.downloadHistoryStates,
    ActionCode.DOWNLOAD_HISTORY_STATES,
  )
    .setActionURL('/v1/wf/document/DownloadWfInfoDocument')
    .build();

  public readonly associaAutofattura = new ActionBuilder(
    SingleActionsCode.associaAutofattura,
    ActionCode.ASSOCIA_AUTOFATTURA,
  ).build();
  public readonly moveToTrash = new ActionBuilder(SingleActionsCode.moveToTrash, ActionCode.MOVE_TO_TRASH)
    .setActionURL('/Trash/command/MoveToTrash')
    .build();

  constructor(private singleDocumentCallback: SingleDocumentCallback) {}
}

export enum SingleActionsCode {
  editKeys = 'editKeys',
  editKeysDisabled = 'editKeysDisabled',
  editKeysWithoutCheck = 'editKeysWithoutCheck',
  viewDocument = 'viewDocument',
  pdfDownload = 'pdfDownload',
  xmlDownload = 'xmlDownload',
  startWorkflow = 'startWorkflow',
  downloadAttachmentReceivable = 'downloadAttachmentReceivable',
  downloadAttachmentPayable = 'downloadAttachmentPayable',
  multiHideDocumentsAction = 'multiHideDocumentsAction',
  multiRestoreDocumentsAction = 'multiRestoreDocumentsAction',
  addNotes = 'addNotes',
  downloadHistoryStates = 'downloadHistoryStates',
  associaAutofattura = 'associaAutofattura',
  moveToTrash = 'moveToTrash',
  esitoDownload = 'esitoDownload',
}
