<div class="m-4 p-4 bg-card" [ngClass]="{ content: !loading }">
  <div class="h-100" *hubLoading="loading; component: loadingTemplate; style: { height: '200px' }">
    <div class="h-100 d-flex flex-column gap-3">
      <div class="mb-4 d-flex gap-2">
        <button
          *ngIf="!showAction"
          class="btn-ghost"
          [attr.aria-label]="folderId ? 'Torna ai fascicoli' : 'Torna all\'inizio'"
          (click)="onBackClick()"
        >
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <h1>
          <ng-container *ngIf="showAction">Nuovo documento</ng-container>
          <ng-container *ngIf="path === 'multiple'">Caricamento multiplo</ng-container>
          <ng-container *ngIf="path === 'single'">Caricamento singolo</ng-container>
        </h1>
      </div>

      <!-- TIPO INSERIMENTO -->
      <ng-container *ngIf="showAction">
        <h2 class="text-uppercase">Scegli il percorso di caricamento</h2>

        <div class="flex-grow-1 d-flex gap-4">
          <button
            class="tile d-flex flex-column justify-content-between px-3 py-4"
            (click)="handleQueryParams({ action: 'upload' })"
          >
            <div class="d-flex flex-column gap-4">
              <div class="fw-bold tile-title">Caricamento multiplo</div>
              <div class="d-flex align-items-center gap-3">
                <span class="step-num d-flex align-items-center justify-content-center">1</span>
                <span>Scegli la classe documentale</span>
              </div>
              <div class="d-flex align-items-center gap-3">
                <span class="step-num d-flex align-items-center justify-content-center">2</span>
                <span>Scegli la serie documentale</span>
              </div>
              <div class="d-flex align-items-center gap-3">
                <span class="step-num d-flex align-items-center justify-content-center">3</span>
                <span>Carica i documenti o un lotto</span>
              </div>
            </div>
            <div class="start-element w-100">
              <span>Inizia</span>
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </div>
          </button>
          <button
            class="tile d-flex flex-column justify-content-between px-3 py-4"
            (click)="handleQueryParams({ action: 'data-entry' })"
          >
            <div class="d-flex flex-column gap-4">
              <div class="fw-bold tile-title">Caricamento singolo</div>
              <div class="d-flex align-items-center gap-3">
                <span class="step-num d-flex align-items-center justify-content-center">1</span>
                <span>Scegli la classe documentale</span>
              </div>
              <div class="d-flex align-items-center gap-3">
                <span class="step-num d-flex align-items-center justify-content-center">2</span>
                <span>Scegli la serie documentale</span>
              </div>
              <div class="d-flex align-items-center gap-3">
                <span class="step-num d-flex align-items-center justify-content-center">3</span>
                <span>Carica un documento e associa i metadati</span>
              </div>
            </div>
            <div class="start-element w-100">
              <span>Inizia</span>
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </div>
          </button>
        </div>

        <h2 class="mt-5 mb-2 text-uppercase">
          GAWCONS - Come fare a inviare un singolo documento in conservazione indicando manualmente i metadati?
        </h2>
        <div class="tile p-3 mb-4 flex-grow-0">
          <strong>
            La funzione di caricamento del singolo pdf del documento in GAW sarà realizzata entro Febbraio 2025;
          </strong>
          al momento è ancora disponibile sul portale gedonweb.it, dal menu "LOTTI", "INVIO LOTTI" , scegliendo la
          tipologia di spedizione "Documento". Dopo la scelta della grafica relativa alla tipologia del documento che si
          vuole caricare, saranno visualizzati i campi in cui inserire i metadati del singolo documento che saranno
          visibili dall’archivio fiscale.
        </div>
      </ng-container>

      <ng-container *ngIf="showClassification || showSeries || showUploadPage">
        <div class="flex-grow-1 tiles-card d-flex flex-column p-4">
          <div class="stepper d-flex align-items-center gap-3">
            <div
              [@slide]="animateBackButton ? (showBackButton ? 'in' : 'out') : 'hidden'"
              class="d-flex gap-3 align-items-center overflow-hidden"
            >
              <button
                class="ms-2 btn-ghost stepper-back d-flex align-items-center gap-3"
                [attr.tabindex]="showBackButton ? 0 : -1"
                aria-label="Torna al passo precedente"
                [attr.aria-hidden]="showBackButton"
                (click)="goBack()"
              >
                <i class="fa fa-chevron-left" aria-hidden="true"></i>
                <span>Indietro</span>
              </button>
              <span class="stepper-divider"></span>
            </div>

            <div class="mx-4 my-2 flex-grow-1 d-flex justify-content-between align-items-center">
              <div
                class="step d-flex gap-2 align-items-center"
                [ngClass]="{ 'current-step': showClassification, 'previous-step': showSeries || showUploadPage }"
              >
                <span *ngIf="showClassification" class="step-num d-flex align-items-center justify-content-center"
                  >1</span
                >
                <span
                  *ngIf="showSeries || showUploadPage"
                  class="step-num d-flex align-items-center justify-content-center"
                >
                  <i class="fa fa-check" aria-hidden="true"></i>
                </span>
                <span>Scegli la classe documentale</span>
              </div>
              <div
                class="step d-flex gap-2 align-items-center"
                [ngClass]="{ 'current-step': showSeries, 'previous-step': showUploadPage }"
              >
                <span
                  *ngIf="showClassification || showSeries"
                  class="step-num d-flex align-items-center justify-content-center"
                  >2</span
                >
                <span *ngIf="showUploadPage" class="step-num d-flex align-items-center justify-content-center">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </span>
                <span>Scegli la serie documentale</span>
              </div>
              <div class="step d-flex gap-2 align-items-center" [ngClass]="{ 'current-step': showUploadPage }">
                <span class="step-num d-flex align-items-center justify-content-center">3</span>
                <span>
                  {{
                    path === 'multiple' ? 'Carica un documento o un lotto' : 'Carica un documento e associa i metadati'
                  }}
                </span>
              </div>
            </div>
          </div>

          <ng-container
            *hubNewError="
              classificationError || graphicError;
              options: tileErrorPlaceholderErrorOption;
              style: { height: '200px' }
            "
          >
            <!-- CLASSE DOCUMENTALE -->
            <ng-container *ngIf="showClassification">
              <h2 class="text-uppercase mb-3">Scegli la classe documentale</h2>

              <div *hubLoading="classificationLoading; style: { height: '200px' }">
                <ng-container *ngIf="classifications?.length; else noResult">
                  <div
                    class="tiles-grid gap-3"
                    [ngClass]="{
                      'cols-4': classifications.length > 3,
                      'cols-3': classifications.length === 3,
                      'cols-2': classifications.length < 3,
                    }"
                  >
                    <ng-container *ngFor="let classification of classifications; let i = index">
                      <button class="tile px-4 py-5" (click)="classificationClick(classification.code)">
                        <div class="d-flex align-items-center justify-content-between hover-blue">
                          <div class="fw-bold tile-title">{{ classification.description }}</div>
                          <i class="fa fa-chevron-right" aria-hidden="true"></i>
                        </div>
                      </button>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </ng-container>

            <!-- SERIE DOCUMENTALE -->
            <ng-container *ngIf="showSeries">
              <h2 class="text-uppercase mb-3">Scegli la serie documentale</h2>

              <div *hubLoading="graphicsLoading; style: { height: '200px' }">
                <ng-container *ngIf="docSeries?.length; else noResult">
                  <div
                    class="tiles-grid gap-3"
                    [ngClass]="{
                      'cols-4': docSeries.length > 3,
                      'cols-3': docSeries.length === 3,
                      'cols-2': docSeries.length < 3,
                    }"
                  >
                    <ng-container *ngFor="let grafica of docSeries; let i = index">
                      <button class="tile px-4 py-5" (click)="graficaClick(grafica.graphicsCode, grafica.docClassCode)">
                        <div class="d-flex align-items-center justify-content-between hover-blue">
                          <div class="fw-bold tile-title">{{ grafica.description }}</div>
                          <i class="fa fa-chevron-right" aria-hidden="true"></i>
                        </div>
                      </button>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </ng-container>

            <!-- UPLOAD DOCUMENTI -->
            <ng-container *ngIf="showUploadPage">
              <h2 class="text-uppercase mb-3">Carica i documenti o un lotto</h2>

              <div
                class="d-flex flex-column flex-grow-1 dropzone"
                fileDrop
                type="file"
                (filesDropped)="handleDrop($event)"
                (filesHovered)="dropzoneState($event)"
              >
                <label
                  for="files-lotto"
                  class="flex-grow-1 drop d-flex flex-column align-items-center justify-content-center gap-3"
                  [ngClass]="{ 'file-loaded': fileLoaded }"
                >
                  <ng-container *ngIf="!fileLoaded">
                    <p class="text-uppercase dropzone-paragraph" style="text-align: center">
                      Trascina il file o clicca qui
                    </p>
                    <p class="dropzone-helper" style="text-align: center">
                      File accettati: .zip | Dimensione massima 125 MB
                    </p>

                    <input
                      id="files-lotto"
                      type="file"
                      style="display: none"
                      accept=".zip"
                      aria-label=""
                      (change)="onFileChange($event)"
                    />
                  </ng-container>
                  <ng-container *ngIf="fileLoaded">
                    <p class="text-uppercase file-name">
                      {{ lottoName }}
                    </p>
                    <p class="dropzone-helper d-flex align-items-center gap-2">
                      <span class="icon-wrapper"><i class="fa fa-check" aria-hidden="true"></i> </span>
                      File aggiunto con successo
                    </p>
                  </ng-container>
                </label>
                <div class="d-flex justify-content-end mt-3 gap-3">
                  <button
                    *ngIf="fileLoaded"
                    href="#myModal"
                    class="btn-upload btn btn-white"
                    data-bs-toggle="modal"
                    type="button"
                    (click)="removeLotto()"
                  >
                    Ricarica documento
                  </button>
                  <button
                    href="#myModal"
                    class="btn-upload btn btn-primary"
                    data-bs-toggle="modal"
                    type="button"
                    [disabled]="!fileLoaded"
                    (click)="uploadLotto()"
                  >
                    Salva e invia
                  </button>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <ng-template #noResult>
      <ng-container *ngIf="tilesLoaded; else loading">
        <div class="page-subtitle no-results">
          La configurazione che ti è stata assegnata non ti consente di
          {{ action === 'upload' ? 'inserire' : 'creare' }} documenti
        </div>
      </ng-container>
    </ng-template>

    <ng-template #loading>
      <div *hubLoading="true"></div>
    </ng-template>
  </div>
</div>
