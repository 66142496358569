<div class="row mx-0 px-3">
  <div class="col-md-10">
    <div class="details-title" *ngIf="documentDetails$ | async as documentDetails">
      <ng-container *ngIf="documentSeriesColors$ | async as documentSeriesColors">
        <ng-container *ngFor="let documentSeriesColor of documentSeriesColors">
          <ng-container
            *ngIf="documentSeriesColor.description.toLowerCase() === documentDetails.docSeriesDescription.toLowerCase()"
          >
            <span class="badge doctype-label" [ngStyle]="{ 'background-color': documentDetails.keys['hexLightMode'] }">
              <span class="badge-text">{{ documentDetails.docSeriesDescription || documentDetails.docSeriesId }}</span>
            </span>
          </ng-container>
        </ng-container>
      </ng-container>

      <div class="t nd">{{ getDocLabel(documentDetails.metadataList) }}</div>
    </div>
    <gaw-gawlotti-details-main [data]="data"></gaw-gawlotti-details-main>
  </div>
  <div class="col-md-2">
    <div class="row">
      <h4 class="action">Azioni</h4>
      <button class="btn btn-primary btn-action w-100" type="button" (click)="showModal()">
        <i class="btn-action-sidebar" [ngClass]="'far fa-file-pdf'"></i>
        Esporta
      </button>
    </div>
  </div>
</div>
