import { Injectable } from '@angular/core';
import { AnomalyDetails } from 'app/entities/anomalies/anomaly-details';
import { DocumentsDoubleKeys } from 'app/entities/anomalies/documents-double-keys';
import { ModifyAnomaly } from 'app/entities/anomalies/modify-anomaly';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import utc from 'dayjs/plugin/utc';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AnomaliesHttpService } from './anomalies-http.service';

dayjs.extend(utc);
dayjs.locale('it');

@Injectable({
  providedIn: 'root',
})
export class AnomaliesService {
  error;
  private documents$ = new ReplaySubject<DocumentsDoubleKeys[]>(1);
  private documentsError$ = new BehaviorSubject<boolean>(false);

  constructor(private anomaliesHttpService: AnomaliesHttpService) {}

  whenDoubleKeys(idSospeso: unknown): Observable<DocumentsDoubleKeys[]> {
    return this.anomaliesHttpService.whenDoubleKeys(idSospeso).pipe(
      tap((documents) => {
        this.sendDocumentsError(false);
        this.sendDoubleKeys(documents);
      }),
    );
  }

  sendDoubleKeys(documents: DocumentsDoubleKeys[]) {
    this.documents$.next(documents);
  }

  whenAnomalyDetails(licenseId: string, siaCode: string, codGrafica, idSospeso: unknown): Observable<AnomalyDetails> {
    return this.anomaliesHttpService.whenAnomalyDetails(licenseId, siaCode, codGrafica, idSospeso).pipe(
      map((value) => ({
        ...value,
        campi: value.campi.map((value1) => ({
          ...value1,
          value: value1.tipo === 'D' ? this.utcToShort(value1.value, 'YYYY-MM-DD') : value1.value,
        })),
      })),
    );
  }

  utcToShort(value: string, format: string) {
    if (value) {
      // Value è un valore UTC
      const date = dayjs.utc(value).local();

      if (date.isValid()) {
        return format ? date.format(format) : date.toISOString();
      }

      return value;
    }
    return value;
  }

  whenMetadataList(idSospeso: string, docSeriesId: string) {
    return this.anomaliesHttpService.whenMetadataList(idSospeso, docSeriesId);
  }

  whenDocumentsError() {
    return this.documentsError$.asObservable();
  }

  modifyAnomaly(anomaly: ModifyAnomaly): Observable<unknown> {
    return this.anomaliesHttpService.modifyAnomaly(anomaly);
  }

  private sendDocumentsError(value: boolean) {
    this.documentsError$.next(value);
  }
}
