import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { cloneAbstractControl } from 'app/core/common/utilities/clone-abstract-control';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { InvoiceFields } from 'app/entities/invoice-pa/invoice-fields';
import { InvoiceMultiValues } from 'app/entities/invoice-pa/invoice-multi-values';

@Component({
  selector: 'app-modal-section-array',
  templateUrl: './modal-section-array.component.html',
  styleUrls: ['./modal-section-array.component.scss'],
})
export class ModalSectionArrayComponent implements OnInit {
  @Input() field: InvoiceFields;
  @Input() parentForm: UntypedFormGroup;
  public formArray: UntypedFormArray;
  public faIcons = FaIcons;

  ngOnInit() {
    this.formArray = this.parentForm.get(this.field.formArrayName) as UntypedFormArray;
  }

  add() {
    const form: UntypedFormGroup = cloneAbstractControl(this.field.emptyForm);
    form.reset();
    this.formArray.push(form);
  }

  deleteElement(index: number) {
    if (this.formArray.controls.length === 1) {
      return;
    }

    this.formArray.removeAt(index);
  }

  findLabelByValue(value: string, list: InvoiceMultiValues[]): string {
    const label = list.find((c) => c.value === value);
    return label ? label.label : '';
  }
}
