<div class="section-list">
  <div class="section-list-header">
    <div>
      <div class="name header">Indicatore</div>
      <div class="description header">Descrizione</div>
      <div style="min-width: 100px" class="actions header">Azioni</div>
    </div>
  </div>
  <ng-template #noResult>
    <div>
      <p class="no-results">Nessun risultato.</p>
    </div>
  </ng-template>
  <ng-container *ngIf="rowData.length > 0; else noResult">
    <div *ngFor="let item of rowData">
      <div class="name">{{ item.name }}</div>
      <div class="description">{{ item.description }}</div>
      <div class="action">
        <button
          class="btn action"
          [ngClass]="{
            'btn-primary': !item.visible,
            'btn-danger': item.visible,
          }"
          (click)="toggleVisibility(item)"
        >
          {{ item.visible ? 'Nascondi' : 'Aggiungi' }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
