import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.locale('it');

/**
 * Pipe che controlla se il valore è una data utc e la formatta in DD/MM/YYYY HH:mm:ss
 */
@Pipe({
  name: 'utcToLocalDate',
})
export class UtcToLocalDatePipe implements PipeTransform {
  transform(value: Date | string | number | dayjs.Dayjs, format: string): string {
    if (value) {
      // Value è un valore UTC
      const date = dayjs.isDayjs(value) ? value.utc().local() : dayjs(value).utc().local();

      if (date.isValid()) {
        return format ? date.format(format) : date.toISOString();
      }

      return value.toString();
    }

    return '';
  }
}
